/**
 * A reducer should always have pure functions.
 * Do not inject any dependency or service in reducer.
 */
import { createReducer, on, Action } from '@ngrx/store';
import * as PageActions from './page.action';
import { CharField, Field, FieldDynamicAttributes, FieldType, Button, DropdownField, DropdownMenuField, TypeaheadField, TableElement, RadioField, DateTimeField, StaticTextField, StaticIconField, TabElement, StaticImageField, DynamicField, FileField } from '@hmi/ui-component';
import { EvaluateExprComponent } from '@hmi/extensions';
import { Dictionary } from '@hmi/modellib';
import * as _ from "lodash";
import { CheckboxField } from 'libs/ui-component/src/lib/form/interfaces/checkbox-field';
import { Section } from 'libs/ui-component/src/lib/form/interfaces/section';

export interface PageState {
    loading: any,
    sectionWiseFields: any,
    sectionWiseHeaderFields: any,
    sectionWiseNavFields: any,
    sectionWiseFooterFields: any,
    uiViewState: Array<Field<any>>,
    uiDynamicAttributeState: any,
    uiHeaderDynamicAttributeState: any,
    uiNavDynamicAttributeState: any,
    uiFooterDynamicAttributeState: any,
    uiData: any,
    dataModel: any,
    fieldConfig: any,
    sectionConfig: any,
    headerDataModel: any,
    headerFieldConfig: any,
    headerSectionConfig: any,
    navDataModel: any,
    navFieldConfig: any,
    navSectionConfig: any,
    footerDataModel: any,
    footerFieldConfig: any,
    footerSectionConfig: any
  }

export const initialState: PageState = {
    loading: { fullPageCount: 0},
    sectionWiseFields: {},
    sectionWiseHeaderFields: {},
    sectionWiseNavFields: {},
    sectionWiseFooterFields: {},
    uiViewState: [],
    uiDynamicAttributeState: {},
    uiHeaderDynamicAttributeState: {},
    uiNavDynamicAttributeState: {},
    uiFooterDynamicAttributeState: {},
    uiData: {},
    dataModel: {},
    fieldConfig: {},
    sectionConfig: [],
    headerDataModel: {},
    headerFieldConfig: {},
    headerSectionConfig: [],
    navDataModel: {},
    navFieldConfig: {},
    navSectionConfig: [],
    footerDataModel: {},
    footerFieldConfig: {},
    footerSectionConfig: []
}

export const reducer = createReducer(
    initialState,
    on(PageActions.ShowLoader, showLoader),
    on(PageActions.HideLoader, hideLoader),
    on(PageActions.UpdateFieldValue, updateFieldValue),
    on(PageActions.CheckForUpdate, checkForUpdate),
    on(PageActions.LoadPageConfigAndData, loadPageConfigAndData),
    on(PageActions.LoadPageConfigAndDataSuccess, loadPageConfigAndDataSuccess),
    on(PageActions.LoadPageConfigAndDataFail, loadPageConfigAndDataFail),
    on(PageActions.ClearPageConfigAndData, clearPageConfigAndDataSuccess),
    on(PageActions.LoadHeaderConfigAndData, loadHeaderConfigAndData),
    on(PageActions.LoadHeaderConfigAndDataSuccess, loadHeaderConfigAndDataSuccess),
    on(PageActions.LoadHeaderConfigAndDataFail, loadHeaderConfigAndDataFail),
    on(PageActions.ClearHeaderConfigAndData, clearHeaderConfigAndDataSuccess),
    on(PageActions.LoadNavConfigAndData, loadNavConfigAndData),
    on(PageActions.LoadNavConfigAndDataSuccess, loadNavConfigAndDataSuccess),
    on(PageActions.LoadNavConfigAndDataFail, loadNavConfigAndDataFail),
    on(PageActions.ClearNavConfigAndData, clearNavConfigAndDataSuccess),
    on(PageActions.LoadFooterConfigAndData, loadFooterConfigAndData),
    on(PageActions.LoadFooterConfigAndDataSuccess, loadFooterConfigAndDataSuccess),
    on(PageActions.LoadFooterConfigAndDataFail, loadFooterConfigAndDataFail),
    on(PageActions.ClearFooterConfigAndData, clearFooterConfigAndDataSuccess)
);

export function iterateArrayMap(arrayMap: Map<string, Array<any>>, callBackFn): any {
  const newArrayMap: Map<string, Array<any>> = _.cloneDeep(arrayMap);
  Object.entries(newArrayMap).map( keyValueArray => {
    keyValueArray[1].map((obj, index, arr) => {
      arr[index] = callBackFn(obj, keyValueArray[0], index);
    });
    newArrayMap[keyValueArray[0]] = keyValueArray[1];
  });
  return newArrayMap;
}

function iterateArrayMapAndModify(arrayMap: Map<string, Array<any>>, callBackFn): any {
  Object.entries(arrayMap).map( keyValueArray => {
    keyValueArray[1].forEach(obj => {
      return callBackFn(obj);
    });
    arrayMap[keyValueArray[0]] = keyValueArray[1];
  });
  return arrayMap;
}

 export const getFieldAttributeDictionary = function (fieldsDynamicAttribute ) {
    const fieldsDictionary = new Dictionary<string>();
    iterateArrayMap(fieldsDynamicAttribute, (fieldAttrVal: FieldDynamicAttributes) => {
      fieldsDictionary.Add(fieldAttrVal.name, fieldAttrVal.value);
    });
    return fieldsDictionary;
}

function showLoader (state) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount + 1;
  return { ...state, loading };
}

function hideLoader (state) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  return { ...state, loading };
}

function updateFieldValue (state, payload) {
    const fieldsDynamicAttribute = iterateArrayMap(state.uiDynamicAttributeState, (fieldAttrVal: FieldDynamicAttributes) => {
      if (fieldAttrVal.id === payload.id) {
        fieldAttrVal.value = payload.value;
        fieldAttrVal.isValueModified = true;
        if (payload.isValueOverride) {
          fieldAttrVal.isValueOverride = true;
        } else {
          fieldAttrVal.isValueOverride = false;
        }
      } else {
        fieldAttrVal.isValueModified = false;
        fieldAttrVal.isValueOverride = false;
      }
      return fieldAttrVal;
    });
    return { ...state, uiDynamicAttributeState: fieldsDynamicAttribute };
}

function checkForUpdate (state, payload) {
  const fieldsDynamicAttribute = _.cloneDeep(state.uiDynamicAttributeState); //TODO: Remove clonedeep
  let field;
  if (payload.field) {
    field = payload.field;
  } else if (payload.id) {
    iterateArrayMap(state.uiDynamicAttributeState, (fieldAttrVal: FieldDynamicAttributes) => {
      if (fieldAttrVal.id === payload.id) {
        field = fieldAttrVal;
      }
    });
  }
  if (field) {
    matchForExpression(fieldsDynamicAttribute, field, payload.sharedData, true);
  } else if (payload.sharedDataAttributeList && payload.sharedDataAttributeList.length) {
    payload.sharedDataAttributeList.forEach(
      sharedDataAttribute => matchForExpression(fieldsDynamicAttribute, null, payload.sharedData, true, sharedDataAttribute)
    );
  }

  return { ...state, uiDynamicAttributeState: fieldsDynamicAttribute };
}

function loadPageConfigAndData (state) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount + 1;
  return Object.assign({}, state, { loading });
}

function loadPageConfigAndDataSuccess (state, payload) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  // sectionConfig, fieldConfig and dataModel will be used to store only backend changes
  const sectionConfig = convertSectionData(payload.data[0].layout);
  //const sectionConfig = payload.data[0];
  const fieldConfig = payload.data[1];
  const dataModel = payload.data[2];

  //TODO: Below codes should we activated when want to track the UI state throughout the application.
  //  Right now the changes in form field value and expression are tracked locally.
  //  According to the redux site it would impact performance to update the state on every keystroke
  //  and refresh all fields. Ref: https://redux.js.org/faq/organizing-state
  // const uiViewState = payload.data[1];

  // const uiData = iterateArrayMap(fieldConfig, fieldObj => {
  //   let newFieldObj = {
  //     id: fieldObj.baseProperties.id,
  //     value: null
  //   };
  //   if (fieldObj.accessor) {
  //     newFieldObj.value = _.get(dataModel, fieldObj.accessor);
  //   }
  //   return newFieldObj;
  // });
  //return { ...state, sectionConfig, fieldConfig, dataModel, uiViewState, uiDynamicAttributeState, uiData };
  const sectionWiseFields:Map<string,Array<Field<any>>> = convertFields(fieldConfig);

  const uiDynamicAttributeState = assignValueFromAPIOrSharedData(fieldConfig, dataModel, sectionWiseFields, payload);

  executeExpressionOnLoad(uiDynamicAttributeState, payload.sharedData);
  return { ...state, loading, sectionConfig, fieldConfig, dataModel, sectionWiseFields, uiDynamicAttributeState };

}

function loadPageConfigAndDataFail (state, response) {
  console.error(JSON.stringify(response.error));
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  return { ...state, loading };
}

function clearPageConfigAndDataSuccess (state, payload) {
  const sectionConfig = [];
  const fieldConfig = {};
  const dataModel = {};
  const sectionWiseFields = {};
  const uiDynamicAttributeState = {};

  return { ...state, sectionConfig, fieldConfig, dataModel, sectionWiseFields, uiDynamicAttributeState };
}

function loadHeaderConfigAndData (state) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount + 1;
  return Object.assign({}, state, { loading });
}

function loadHeaderConfigAndDataSuccess (state, payload) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  // sectionConfig, fieldConfig and dataModel will be used to store only backend changes
  const headerSectionConfig = convertSectionData(payload.data[0].layout);
  // const headerSectionConfig = payload.data[0];
  const headerFieldConfig = payload.data[1];
  const headerDataModel = payload.data[2];
  const sectionWiseHeaderFields:Map<string,Array<Field<any>>> = convertFields(headerFieldConfig);
  const uiHeaderDynamicAttributeState = assignValueFromAPIOrSharedData(headerFieldConfig, headerDataModel, sectionWiseHeaderFields, payload);
  executeExpressionOnLoad(uiHeaderDynamicAttributeState, payload.sharedData);
  return { ...state, loading, headerSectionConfig, headerFieldConfig, headerDataModel, sectionWiseHeaderFields, uiHeaderDynamicAttributeState };

}

function assignValueFromAPIOrSharedData(fieldConfig, dataModel, sectionWiseFields, payload) {
  return iterateArrayMap(fieldConfig, (fieldObj, val, index) => {
    if (fieldObj.accessor) {
      sectionWiseFields[val][index].value = _.get(dataModel, fieldObj.accessor) || _.get(payload, fieldObj.accessor);
      fieldObj.value = _.cloneDeep(sectionWiseFields[val][index].value);
    }
    if (fieldObj.optionList && fieldObj.optionList.length) {
      _.forEach(fieldObj.optionList, (option, key) => {
        if (option.accessor) {
          sectionWiseFields[val][index].optionList[key].value = _.get(dataModel, option.accessor) || _.get(payload, option.accessor);
          option.value = _.cloneDeep(sectionWiseFields[val][index].optionList[key].value);
        }
      });
    }
    return new FieldDynamicAttributes(fieldObj);
  });
}

function loadHeaderConfigAndDataFail (state, response) {
  console.error(JSON.stringify(response.error));
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  return { ...state, loading };
}

function clearHeaderConfigAndDataSuccess (state, payload) {
  const headerSectionConfig = [];
  const headerFieldConfig = {};
  const headerDataModel = {};
  const sectionWiseHeaderFields = {};
  const uiHeaderDynamicAttributeState = {};

  return { ...state, headerSectionConfig, headerFieldConfig, headerDataModel, sectionWiseHeaderFields, uiHeaderDynamicAttributeState };
}

function loadNavConfigAndData (state) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount + 1;
  return Object.assign({}, state, { loading });
}

function loadNavConfigAndDataSuccess (state, payload) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  // sectionConfig, fieldConfig and dataModel will be used to store only backend changes
  const navSectionConfig = convertSectionData(payload.data[0].layout);
  // const navSectionConfig = payload.data[0];
  const navFieldConfig = payload.data[1];
  const navDataModel = payload.data[2];
  const sectionWiseNavFields:Map<string,Array<Field<any>>> = convertFields(navFieldConfig);
  const uiNavDynamicAttributeState = assignValueFromAPIOrSharedData(navFieldConfig, navDataModel, sectionWiseNavFields, payload);
  executeExpressionOnLoad(uiNavDynamicAttributeState, payload.sharedData);
  return { ...state, loading, navSectionConfig, navFieldConfig, navDataModel, sectionWiseNavFields, uiNavDynamicAttributeState };

}

function loadNavConfigAndDataFail (state, response) {
  console.error(JSON.stringify(response.error));
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  return { ...state, loading };
}

function clearNavConfigAndDataSuccess (state, payload) {
  const navSectionConfig = [];
  const navFieldConfig = {};
  const navDataModel = {};
  const sectionWiseNavFields = {};
  const uiNavDynamicAttributeState = {};

  return { ...state, navSectionConfig, navFieldConfig, navDataModel, sectionWiseNavFields, uiNavDynamicAttributeState };
}

function loadFooterConfigAndData (state) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount + 1;
  return Object.assign({}, state, { loading });
}

function loadFooterConfigAndDataSuccess (state, payload) {
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  // sectionConfig, fieldConfig and dataModel will be used to store only backend changes
  const footerSectionConfig = convertSectionData(payload.data[0].layout);
  // const footerSectionConfig = payload.data[0];
  const footerFieldConfig = payload.data[1];
  const footerDataModel = payload.data[2];
  const sectionWiseFooterFields:Map<string,Array<Field<any>>> = convertFields(footerFieldConfig);

  const uiFooterDynamicAttributeState = assignValueFromAPIOrSharedData(footerFieldConfig, footerDataModel, sectionWiseFooterFields, payload);

  executeExpressionOnLoad(uiFooterDynamicAttributeState, payload.sharedData);
  return { ...state, loading, footerSectionConfig, footerFieldConfig, footerDataModel, sectionWiseFooterFields, uiFooterDynamicAttributeState };

}

function loadFooterConfigAndDataFail (state, response) {
  console.error(JSON.stringify(response.error));
  const loading = _.cloneDeep(state.loading);
  loading.fullPageCount = state.loading.fullPageCount - 1;
  return { ...state, loading };
}

function clearFooterConfigAndDataSuccess (state, payload) {
  const footerSectionConfig = [];
  const footerFieldConfig = {};
  const footerDataModel = {};
  const sectionWiseFooterFields = {};
  const uiFooterDynamicAttributeState = {};

  return { ...state, footerSectionConfig, footerFieldConfig, footerDataModel, sectionWiseFooterFields, uiFooterDynamicAttributeState };
}

function convertSectionData(sectionArr): Array<Section> {
  const sectionList: Array<Section> = [];
  _.each(sectionArr, (section, key) => {
    sectionList.push(new Section(section));
  });

  return sectionList;
}

function convertFields (fieldConfig) {
  return iterateArrayMap(fieldConfig, (fieldObj: any) => {
    const fieldType = fieldObj.baseProperties ? fieldObj.baseProperties.type : fieldObj.type;
    if ([FieldType.text, FieldType.email, FieldType.url, FieldType.tel,
    FieldType.textarea, FieldType.password, FieldType.number].indexOf(fieldType) > -1) {
      fieldObj = new CharField(fieldObj);
    } else if ([FieldType.button, FieldType.submit, FieldType.reset, FieldType.link,
      FieldType.navItemGroup].indexOf(fieldType) > -1) {
      fieldObj = new Button(fieldObj);
    } else if (fieldType === FieldType.dropdown) {
      fieldObj = new DropdownField(fieldObj);
    } else if (fieldType === FieldType.typeahead) {
      fieldObj = new TypeaheadField(fieldObj);
    } else if (fieldType === FieldType.table) {
      fieldObj = new TableElement(fieldObj);
    } else if (fieldType === FieldType.radio) {
      fieldObj = new RadioField(fieldObj);
    } else if (fieldType === FieldType.checkbox) {
      fieldObj = new CheckboxField(fieldObj);
    } else if (fieldType === FieldType.staticText) {
      fieldObj = new StaticTextField(fieldObj);
    } else if (fieldType === FieldType.staticIcon) {
      fieldObj = new StaticIconField(fieldObj);
    }  else if (fieldType === FieldType.staticImage) {
      fieldObj = new StaticImageField(fieldObj);
    }  else if (fieldType === FieldType.tab) {
      fieldObj = new TabElement(fieldObj);
    } else if ([FieldType.date, FieldType.time, FieldType.datetime, FieldType.dateInline].indexOf(fieldType) !== -1) {
      fieldObj = new DateTimeField(fieldObj);
    } else if (fieldType === FieldType.dynamicField) {
      fieldObj = new DynamicField(fieldObj);
    } else if (fieldType === FieldType.navMenu) {
      fieldObj = new DropdownMenuField(fieldObj);
    } else if (fieldType == FieldType.file ) {
      fieldObj = new FileField(fieldObj);
    } else {    //For static text and anything else.
      fieldObj = new Field(fieldObj);
    }
    return fieldObj;
  });
}

function executeExpressionOnLoad(fieldsDynamicAttribute, sharedData) {
  executeAllExpressions(fieldsDynamicAttribute, sharedData);
}

function executeAllExpressions(fieldsDynamicAttribute: Map<string, Array<FieldDynamicAttributes>>, sharedData: any, checkModified?: boolean) {
  const evalExpr = new EvaluateExprComponent();
  const fieldDictionary = getFieldAttributeDictionary(fieldsDynamicAttribute);
  iterateArrayMapAndModify(fieldsDynamicAttribute, element => {
    let modified = false;

    modified = isStandardExpressionPropertiesModified(fieldDictionary, sharedData, element, "$ALL$");
    
    if(element.minDate) {
      const value = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.minDate);
      if (value) {
        element.minDateValue = element.convertDateTimeToGivenFormat(value, "date", element.accessorFormat);
      }
      modified = true;
    }
    if(element.maxDate) {
      const value = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.maxDate);
      if (value) {
        element.maxDateValue = element.convertDateTimeToGivenFormat(value, "date", element.accessorFormat);
      }
      modified = true;
    }

    if (checkModified) {
      element.modified = modified;
    } else {
      element.modified = false;
    }
  });
}

function isStandardExpressionPropertiesModified(fieldDictionary: Dictionary<string>, sharedData: any, element: FieldDynamicAttributes, exprName: string) {
  let modified = false, newValue;
  const evalExpr = new EvaluateExprComponent();
  if(element.required && (exprName === "$ALL$" || element.required.includes(exprName))) {
    newValue = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.required);
    if(!_.isEqual(element.requiredValue, newValue)) {
      element.requiredValue = newValue;
      modified = true;
    }
  }
  if(element.readonly && (exprName === "$ALL$" || element.readonly.includes(exprName))) {
    newValue = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.readonly);
    if(!_.isEqual(element.readOnlyValue, newValue)) {
      element.readOnlyValue = newValue;
      modified = true;
    }
  }
  if(element.disabled && (exprName === "$ALL$" || element.disabled.includes(exprName))) {
    newValue = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.disabled);
    if(!_.isEqual(element.disabledValue, newValue)) {
      element.disabledValue = newValue;
      modified = true;
    }
  }
  if(element.visible && (exprName === "$ALL$" || element.visible.includes(exprName))) {
    newValue = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.visible);
    if(!_.isEqual(element.visibleValue, newValue)) {
      element.visibleValue = newValue;
      modified = true;
    }
  }
  return modified;
}

function matchForExpression(fieldsDynamicAttributes: Map<string, Array<FieldDynamicAttributes>>, fieldDynamicAttr: FieldDynamicAttributes, sharedData: any, checkModified?: boolean, sharedDataAttribute?: string) {
  const evalExpr = new EvaluateExprComponent();
  const fieldDictionary = getFieldAttributeDictionary(fieldsDynamicAttributes);
  const exprName = fieldDynamicAttr && fieldDynamicAttr.name ? fieldDynamicAttr.name : sharedDataAttribute;
  iterateArrayMapAndModify(fieldsDynamicAttributes, element => {
    let modified = false, newValue;
    modified = isStandardExpressionPropertiesModified(fieldDictionary, sharedData, element, exprName);
    if(element.minDate && element.minDate.includes(exprName)) {
      const value = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.minDate);
      if (value) {
        element.minDateValue = element.convertDateTimeToGivenFormat(value, "date", element.accessorFormat);
      }
      modified = true;
    }
    if(element.maxDate && element.maxDate.includes(exprName)) {
      const value = evalExpr.evaluateExpression(fieldDictionary, sharedData, element.maxDate);
      if (value) {
        element.maxDateValue = element.convertDateTimeToGivenFormat(value, "date", element.accessorFormat);
      }
      modified = true;
    }

    if (checkModified) {
      element.modified = modified;
    } else {
      element.modified = false;
    }
  });
}
