import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ElementsModule } from '@hmi/ui-component';
import { SectionComponent } from './section/section.component';
import { SectionContainerStyleDirective } from '../directives/section-container-style.directive';



@NgModule({
  declarations: [SectionComponent, SectionContainerStyleDirective],
  imports: [
    SharedModule,
    ElementsModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  exports: [SectionComponent]
})
export class SectionModule { }
