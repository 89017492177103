import { Field } from './field';
import { DropdownOption } from './dropdown-field';

export class DropdownMenuField extends Field<string> {
    optionList: Array<DropdownOption>;
    icon?: string;
    imageUrl?: string;

    constructor(options: { 
        optionList: Array<DropdownOption>;
        type : string;
        value: string;
        icon?: string;
        imageUrl?: string;
      }) {
        super(options);
        this.optionList = options.optionList;
        this.icon = options.icon;
        this.imageUrl = options.imageUrl;
      }
}