import {Component, Input, OnDestroy, OnInit, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import { ACTION_TYPE } from '@lib-model/events';
import { EventAction } from '../../services/event.action';
import { FileField } from '../../form/interfaces/file-upload-field';
import { ViewComponent } from '../view/view.component';
import * as _ from "lodash";
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {UntypedFormGroup} from "@angular/forms";
import {FormsService} from "@lib-service/forms.service";

@Component({
  selector: 'hmi-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() fieldObj: FileField;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  @Input() editMode: boolean;
  @Input() isTemplateOnly = false;
  formGroupObj: UntypedFormGroup;
  data: any;
  debounceValueChange: Subject<string> = new Subject();
  subscription: any;
  @ViewChild('inputLabel') inputLabel: ElementRef;
  @ViewChild('fileRef') fileRef: ElementRef;
  eventSubscriptionArr: any;


  constructor(private formService: FormsService, eventAction: EventAction) {
    super(eventAction);
  }

  ngOnInit(): void {
    this.formGroupObj = this.formService.createInputControl(this.fieldObj, this.dynamicAttributes);
    this.subscription = this.fieldObj.action.subscribe(actionObj => {
      if (actionObj.actionType === ACTION_TYPE.CLEAR_COMPONENT_DATA) {
        this.clearValue();
      }
    });
  }

  ngAfterViewInit() {
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, this.fileRef?.nativeElement, this.fieldObj);
    if (this.dynamicAttributes?.value?.name) {
      this.inputLabel.nativeElement.innerHTML = this.dynamicAttributes.value.name;
    } 
  }

  applyLabelPlacementClass() {
    let className = 'input-label__placement-top';
    if (this.fieldObj.layoutProperties.labelPlacement
       && this.fieldObj.layoutProperties.labelPlacement.toLowerCase() === 'left') {
      className = 'input-label__placement-left';
    }

    return className;
  }

  onValueChange(event) {
    console.log('I am in onValueChange()');
    if(!this.isTemplateOnly
      && this.fieldObj.value !== this.formGroupObj.value[this.fieldObj.baseProperties.name]) { //Value change should be triggered only if actual value has changed.
        const reader = new FileReader();
        reader.onload = this.handleFileLoad.bind(this, event.target.files[0]);
        reader.readAsText(event.target.files[0]);
    }
  }

  handleFileLoad(file: File, event) {
    let dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
    if (!dynamicAttributes) {
      dynamicAttributes = {};
    }
    dynamicAttributes.value = {
      name: file?.name,
      lastModified: file?.lastModified,
      size: file?.size,
      type: file?.type,
      fakePath: this.formGroupObj.value[this.fieldObj.baseProperties.name],
      data: event.target.result,
      file: file
    };
    this._dataChange.emit({ dynamicData: dynamicAttributes });
    if (this.formGroupObj.value[this.fieldObj.baseProperties.name] !== null) {
      this.fieldObj.onValueChange && this.fieldObj.onValueChange.actions
      && this._dataChange.emit({ actions: this.fieldObj.onValueChange.actions });
    }
    this.inputLabel.nativeElement.innerHTML = file?.name;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  clearValue() {
    this.formGroupObj.get(this.fieldObj.baseProperties.name).setValue(null);
    const dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
    dynamicAttributes.value = null;
    this._dataChange.emit({ dynamicData: dynamicAttributes });
    this.fieldObj.onValueClear && this.fieldObj.onValueClear.actions
    && this._dataChange.emit({ actions: this.fieldObj.onValueClear.actions });
    this.inputLabel.nativeElement.innerHTML = "Choose file";
  }

  ngOnDestroy() {
    this.formService.deleteInputControl(this.fieldObj);
    this.subscription.unsubscribe();
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }

}
