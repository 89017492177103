import { Field } from "./field";

export class CharField extends Field<string> {
  displayAsText: boolean;
  rowNum: number;
  showCharLengthUnit: boolean;
  maxChar: number;
  placeholder: string;
  hideSpinner: boolean;
  minRequirementPattern: string;
  minLength: number;
  maxLength: number;
  minRange: number;
  maxRange: number;
  constructor(options :{
    value?: string;
    name?: string;
    label?: string;
    type?: string;
    id?:string;
    placeholder?: string;
    height?: number;
    width?: number;
    rowNum?: number;
    showCharLengthUnit?: boolean;
    maxChar?: number;
    hideSpinner?: boolean;
    minRequirementPattern?: string;
    minLength?: number;
    maxLength?: number;
    minRange?: number;
    maxRange?: number;
    } = {}) {
    super(options);
    this.maxChar = options.maxChar;
    this.rowNum = options.rowNum;
    this.placeholder = options.placeholder;
    this.showCharLengthUnit = options.showCharLengthUnit;
    this.hideSpinner = options.hideSpinner || false;
    this.minRequirementPattern = options.minRequirementPattern;
    if (!isNaN(options.minLength)) {
      this.minLength = options.minLength;
    }

    if (!isNaN(options.maxLength)) {
      this.maxLength = options.maxLength;
    }

    if (!isNaN(options.minRange)) {
      this.minRange = options.minRange;
    }

    if (!isNaN(options.maxRange)) {
      this.maxRange = options.maxRange;
    }

    if (options.value !== null && options.value !== undefined && options.value !== '') {
      this.value = options.value;
    }
  }
  getValue(): string {
    if (this.value === undefined) {
      return null;
    }
    return this.value;
  }

}
