import { Component, OnInit, Input, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DropdownField, DropdownOption } from '../../form/interfaces/dropdown-field';
import { ViewComponent } from '@elements/view/view.component';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { DropdownMenuField } from '../../form/interfaces/dropdown-menu-field';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { EventAction } from '@lib-service/event.action';


@Component({
  selector: 'hmi-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.css']
})
export class DropdownMenuComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() name: string;
  @Input() fieldObj: DropdownMenuField;
  @Input() dynamicAttributes: FieldDynamicAttributes;

  @Input() optionList: DropdownOption[] = [];
  @ViewChild('ddRef') ddRef: ElementRef;
  eventSubscriptionArr: any;
  
  constructor(eventAction: EventAction) {
    super(eventAction);
   }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, this.ddRef?.nativeElement, this.fieldObj);
  }

  ngOnDestroy() {
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }
}
