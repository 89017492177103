import { Injectable } from '@angular/core';
import { InterceptorConfiguration } from '@lib-model/app-interfaces';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticated = false;
  interceptorConfigurations: Array<InterceptorConfiguration>;

  constructor(private cookieService: CookieService) {
    this.interceptorConfigurations = [];
  }

  public setInterceptorConfigurations(interceptorConfigurations: Array<InterceptorConfiguration>): void {
    this.interceptorConfigurations = interceptorConfigurations;
  }

  public getInterceptorConfigurations(): Array<InterceptorConfiguration> {
    return this.interceptorConfigurations;
  }

  public setAutheticationSuccessful(): void {
    this.authenticated = true;
  }

  public setAutheticationFailure(): void {
    this.authenticated = false;
    this.cookieService.deleteAll();
  }

  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  public getCookieValue(cookieName: string): any {
    return this.cookieService.get(cookieName);
  }
}
