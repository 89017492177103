import { Component, OnInit, Inject } from '@angular/core';
import { Parser } from 'expr-eval';
import * as _ from "lodash";
import { FieldValidationStatus } from '@lib-model/constants';

@Component({
  selector: 'hmi-evaluate-expr',
  template: '',
  styleUrls: []
})
export class EvaluateExprComponent implements OnInit {
  parser = new Parser();
  constantValueList = [FieldValidationStatus.$DIRTY, FieldValidationStatus.$PRISTINE, FieldValidationStatus.$TOUCHED];
  constructor() { }

  evaluateExpression(dict: any, sharedData, expression: string, fieldsStatus?: any): boolean {
    const expr = this.parser.parse(expression);
    const variables = expr.variables({ withMembers: true });
    const obj = {};
    variables.forEach(element => {
      let elementArr = element.split(".");
      if (elementArr[1] && this.constantValueList.indexOf(elementArr[1]) !== -1) {
        //obj[element] = fieldsStatus[elementArr[0]] && fieldsStatus[elementArr[0]][elementArr[1]];
        _.set(obj, element, fieldsStatus[elementArr[0]] && fieldsStatus[elementArr[0]][elementArr[1]]);
      } else if (dict && dict.ContainsKey && dict.ContainsKey(elementArr[0])) {
        let tempObj = {};
        tempObj[elementArr[0]] = dict.Value(elementArr[0]);
        const value = _.get(tempObj, element);
        _.set(obj, element, value);                
      } else if (dict && dict.hasOwnProperty(elementArr[0])) {
        let tempObj = {};
        tempObj[elementArr[0]] = dict[elementArr[0]];
        const value = _.get(tempObj, element);
        _.set(obj, element, value);
      } else if (sharedData && sharedData.hasOwnProperty(elementArr[0])) {
        let tempObj = {};
        tempObj[elementArr[0]] = sharedData[elementArr[0]];
        const value = _.get(tempObj, element);
        _.set(obj, element, value);
      } else {
        // throw error
        console.log('I shouldn\'t be here');
      }
      if (!obj[elementArr[0]]) {
        _.set(obj, element, "");
      }
    });
    let retVal;
    try {
      retVal = expr.evaluate(obj);
    }
    catch (err) {
      console.log('Error in evaluating expresstion -', expression, 'Error - ', err);
      retVal = false;
    }

    return retVal;
  }

  ngOnInit() {
  }

}
