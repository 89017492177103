import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import { ViewComponent } from '../view/view.component';
import { CheckboxField } from '../../form/interfaces/checkbox-field';
import { DropdownOption } from '../../form/interfaces/dropdown-field';
import * as _ from "lodash";
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { EventAction } from '@lib-service/event.action';

@Component({
  selector: 'hmi-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() name: string;
  @Input() fieldObj: CheckboxField;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  // TODO: Remove name from optionList object
  @Input() optionList: DropdownOption[];
  formGroupObj: UntypedFormGroup;
  initialValue: DropdownOption;
  @Input() isTemplateOnly: boolean = false;
  @ViewChild('checkboxRef') checkboxRef: ElementRef;
  eventSubscriptionArr: any;

  constructor(private formService: FormsService,  eventAction: EventAction) {
    super(eventAction);
  }  

  ngOnInit() {
    this.formGroupObj = this.formService.createInputControl(this.fieldObj, this.dynamicAttributes);
  }

  ngAfterViewInit() {
    this.eventSubscriptionArr = [];
    this.checkboxRef?.nativeElement?.querySelectorAll('input[type=checkbox]')?.forEach(element => {
      this.eventSubscriptionArr.push(
        this.attachEventsToElement(this.fieldObj.events, element, this.fieldObj)
      );
    });
  }

  onValueChange(valObj, event) {
    if(!this.isTemplateOnly) { 
      if (event.target.checked) {
        this.dynamicAttributes.value.push(valObj);
      } else {
        const index = _.findIndex(this.dynamicAttributes.value, { value: valObj.value });
        this.dynamicAttributes.value.splice(index, 1);
      }
      
      const dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
      this._dataChange.emit({ dynamicData: dynamicAttributes });
      this.fieldObj.onValueChange && this.fieldObj.onValueChange.actions 
        && this._dataChange.emit({ actions: this.fieldObj.onValueChange.actions });
    }
    
  }

  applyLabelPlacementClass() {
    let className = 'input-label__placement-top';
    if (this.fieldObj.layoutProperties.labelPlacement && this.fieldObj.layoutProperties.labelPlacement.toLowerCase() === 'left') {
      className = 'input-label__placement-left';
    }
    
    return className;
  }

  ngOnDestroy() {
    this.formService.deleteInputControl(this.fieldObj);
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }

}
