import { Field } from './field';

export class StaticImageField extends Field<string> {
  imageUrl: string;
  accessor?: string;
  showDownload?: boolean;
  altText?: string;

  constructor(options: {    
    type?: string;
    imageUrl: string;
    accessor?: string;
    showDownload?: boolean;
    altText?: string;
    css: any;
  }) {
    super(options);
    this.imageUrl = options.imageUrl;
    this.accessor = options.accessor;
    this.showDownload = options.showDownload;
    this.altText = options.altText;
  }
}