import { Component, Input, Type } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewComponent } from '../view/view.component';

@Component({
  selector: 'hmi-modal-confirm',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent extends ViewComponent {
  @Input() public data: ConfirmModalData;

  constructor(public modal: NgbActiveModal) {
    super();
    this.data = {
      "type": "",
      "condition": "",
      "message": {
          "title": "",
          "body": ""
      },
      "yesButtonLabel": "",
      "noButtonLabel": ""
    }
  }
}

export const MODALS: {[name: string]: Type<any>} = {
  CONFIRM_MODAL: ModalComponent
};

export interface ConfirmModalData {
  type: string;
  condition: string;
  message: {
    title: string,
    body: string
  };
  yesButtonLabel: string;
  noButtonLabel: string;
}
