import { Field } from './field';

export class StaticIconField extends Field<string> {
  iconClass: String;

  constructor(options: {
    iconClass: String;
    type?: string;
  }) {
    super(options);
    this.iconClass = options.iconClass;
  }
}