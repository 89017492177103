import { Field } from './field';
import { UrlConfiguration } from '../../models/url-configuration';

export interface ColumnNameAccessorMap {
    colName: string;
    accessor?: string;
    colWidth?: { unit: string; value: Number };
    value?: string;
    iconClass?: string; //Use font-awesome class name for icons
    action?: any;
}

export class TableElement extends Field<Array<any>> {
    colNameAccessorMap?: Array<ColumnNameAccessorMap>;
    accessor: string;
    dataConfig?: UrlConfiguration

    constructor(options: {
        id: string;
        name: string;
        label: string;
        accessor: string;
        colNameAccessorMap?: Array<ColumnNameAccessorMap>;
        dataConfig?: UrlConfiguration;
        value?: Array<any>
    }) {
        super(options);
        this.colNameAccessorMap = options.colNameAccessorMap;
        this.dataConfig = options.dataConfig;
    }

}