import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { CoreService } from '@renderer/services/core.service';
import * as fromRoot from '@shared/store';
import { Store } from '@ngrx/store';
import * as pageActions from '@shared/store/page/page.action';
import { UtilsService } from '@shared/services/utils.service';
import * as _ from "lodash";
import { AppDataService } from '@shared/services/app-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LayoutComponent } from '@renderer/layout/layout.component';
import { CommonService } from '@shared/services/common.service';
import { GenerateCSSService } from '@shared/services/generate-css.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent extends LayoutComponent implements OnInit {
  
  fieldDynamicAttrSubscription: Subscription;
  sectionListSubscription: Subscription;
  pageConfigsAndDataSubscription: Subscription;
  routerSubscription: Subscription;
  isNavbarCollapsed = true;
  navPosition = "static";
  isMobileScreen: boolean;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(public coreService: CoreService, public utilsService: UtilsService, private router: Router,
    private store: Store<fromRoot.State>, public appData: AppDataService, 
    public commonService: CommonService, public generateCSSService: GenerateCSSService) { 
      super(utilsService, coreService, appData, commonService, generateCSSService);
    this.sectionWiseFields$ = this.store.select(fromRoot.selectAllNavSectionFields);
    this.sectionsList$ = this.store.select(fromRoot.selectNavSectionConfig); //Use nav section config
    this.fieldsDynamicAttr$ = this.store.select(fromRoot.selectNavFieldDynamicAttributeState);
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      console.log("New url:"+event.url);
      this.pageConfigsAndDataSubscription && this.pageConfigsAndDataSubscription.unsubscribe();
      this.sectionListSubscription && this.sectionListSubscription.unsubscribe();
      this.fieldDynamicAttrSubscription && this.fieldDynamicAttrSubscription.unsubscribe();
      this.store.dispatch(pageActions.ClearNavConfigAndData({ payload: '' }));
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.isNavbarCollapsed = true;
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.store.dispatch(pageActions.LoadNavConfigAndData({ segment: this.jsonType.TYPE_NAVBAR, sharedData: this.appData.getAllSharedData() }));
    
    this.sectionListSubscription = this.sectionsList$.subscribe( config => {
      this.sectionsList = _.cloneDeep(config);
      this.loadSectionCSS(this.sectionsList, this.jsonType.TYPE_NAVBAR);
      this.generateCSSService.applyCSSConfig();
    });
    this.fieldDynamicAttrSubscription = this.fieldsDynamicAttr$.subscribe( dynamicAttrData => {
      this.dynamicFieldsLoader(dynamicAttrData);
    });
    this.pageConfigsAndDataSubscription = this.sectionWiseFields$.subscribe((data) => {
      this.loadFieldCSS(data, this.jsonType.TYPE_NAVBAR);
      this.pageDataConfigure(data);      
    }, (err) => {
      console.log("Error in loading nav-bar"+err.toString());
    }, () => console.log('Completed'));    
  }
  
  ngAfterViewInit() {
    this.resizeEvent();
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.resizeEvent();
    });
  }

  resizeEvent() {
    this.isMobileScreen = this.commonService.isMobileDevice();
  }

  trackByFn(index) {
    return 'nav' + index;
  }

  fieldValueChange(newFieldObj) {
    this.coreService.handleFieldUserInput(newFieldObj, this.fields, this.fieldsDynamicAttr);
  }

  public customApiCall = (searchConfig, CUSTOM_FIELD_OBJECT?: any) => {
    return this.coreService.callApi(searchConfig, this.fields, this.fieldsDynamicAttr, CUSTOM_FIELD_OBJECT);
  }

  ngOnDestroy() {
    this.isNavbarCollapsed = true;
    this.pageConfigsAndDataSubscription.unsubscribe();
    this.sectionListSubscription.unsubscribe();
    this.fieldDynamicAttrSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.store.dispatch(pageActions.ClearNavConfigAndData({ payload: '' }));
    this.resizeSubscription$.unsubscribe();
  }
}
