import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ViewComponent } from '@elements/view/view.component';

import { RadioField } from '../../form/interfaces/radio-field';
import { DropdownOption } from '../../form/interfaces/dropdown-field';
import { UntypedFormGroup } from '@angular/forms';
import { FormsService } from '@lib-service/forms.service';
import * as _ from "lodash";
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { EventAction } from '@lib-service/event.action';

@Component({
  selector: 'hmi-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  radioModel: any;

  @Input() name: string;
  @Input() fieldObj: RadioField;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  // TODO: Remove name from optionList object
  @Input() optionList: DropdownOption[];
  formGroupObj: UntypedFormGroup;
  initialValue: DropdownOption;
  @Input() isTemplateOnly = false;
  @ViewChild('radioRef') radioRef: ElementRef;
  eventSubscriptionArr: any;

  constructor(private formService: FormsService, eventAction: EventAction) {
    super(eventAction);
  }  

  ngOnInit() {
    const index = _.findIndex(this.optionList, { value: _.isObject(this.dynamicAttributes.value) && this.dynamicAttributes.value.value });
    this.dynamicAttributes.value = this.optionList[index];  //Assignment from the list required to set default selected
    this.formGroupObj = this.formService.createInputControl(this.fieldObj, this.dynamicAttributes);
    console.log("Option list: " + JSON.stringify(this.optionList));

  }

  ngAfterViewInit() {
    this.eventSubscriptionArr = [];
    this.radioRef?.nativeElement?.querySelectorAll('input[type=radio]')?.forEach(element => {
      this.eventSubscriptionArr.push(
        this.attachEventsToElement(this.fieldObj.events, element, this.fieldObj)
      );
    });
  }

  onValueChange(value) {
    if(!this.isTemplateOnly
      && (!this.formGroupObj.value[this.fieldObj.baseProperties.name] 
        || this.formGroupObj.value[this.fieldObj.baseProperties.name].value !== value.value)) { //Value change should be triggered only if actual value has changed.
        console.log("LHS = " + this.fieldObj.getValue());
        console.log("RHS = " + this.formGroupObj.value[this.fieldObj.baseProperties.name]);
        this.dynamicAttributes.value = value;
      const dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
      this._dataChange.emit({ dynamicData: dynamicAttributes });
      this.fieldObj.onValueChange && this.fieldObj.onValueChange.actions 
        && this._dataChange.emit({ actions: this.fieldObj.onValueChange.actions });
    }
    
  }

  applyLabelPlacementClass() {
    let className = 'input-label__placement-top';
    if (this.fieldObj.layoutProperties.labelPlacement && this.fieldObj.layoutProperties.labelPlacement.toLowerCase() === 'left') {
      className = 'input-label__placement-left';
    }
    
    return className;
  }

  ngOnDestroy() {
    this.formService.deleteInputControl(this.fieldObj);
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }

}
