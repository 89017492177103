import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MessageComponent } from './message/message.component';
import { reducers, metaReducers } from './store';
import { PageEffects } from './store/page/page.effects';


@NgModule({
  declarations: [MessageComponent],
  imports: [
    CommonModule,
    NgbModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([PageEffects])
  ],
  exports: [MessageComponent, NgbModule, CommonModule, FormsModule, ReactiveFormsModule]
})
export class SharedModule { }
