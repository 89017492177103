import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluateExprComponent } from './evaluate-expr/evaluate-expr.component';
import { DynamicStringPipe } from './pipes/utils-pipe.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [EvaluateExprComponent, DynamicStringPipe],
  exports: [EvaluateExprComponent, DynamicStringPipe]
})
export class ExtensionsModule {}
