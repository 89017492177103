import { Field } from "./field";

export class FileField extends Field<string> {
  displayAsText: boolean;
  placeholder: string;
  hideSpinner: boolean;
  allowedExt: string
  constructor(options :{
    value?: string;
    name?: string;
    label?: string;
    type?: string;
    id?:string;
    placeholder?: string;
    height?: number;
    width?: number;
    allowedExt?: string;
    hideSpinner?: boolean;
    } = {}) {
    super(options);
    this.placeholder = options.placeholder;
    this.hideSpinner = options.hideSpinner || false;
    this.allowedExt = options.allowedExt;
    if (options.value !== null && options.value !== undefined && options.value !== '') {
      this.value = options.value;
    }
  }
  getValue(): string {
    if (this.value === undefined) {
      return null;
    }
    return this.value;
  }

}
