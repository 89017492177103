import { Component, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { DropdownOption, DropdownField } from '../../form/interfaces/dropdown-field';
import { ViewComponent } from '../view/view.component';
import { UntypedFormGroup } from '@angular/forms';
import { FormsService } from '@lib-service/forms.service';

import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import * as _ from "lodash";
import { UrlConfiguration } from '../../models/url-configuration';
import { Observable } from 'rxjs';
import { EventType } from '../../models/constants';
import { ACTION_TYPE } from '../../models/events';
import { EventAction } from '@lib-service/event.action';

interface IOption {
  label: string,
  value: string
}
@Component({
  selector: 'hmi-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent extends ViewComponent implements OnDestroy, AfterViewInit {

  @Input() name: string;
  @Input() fieldObj: DropdownField;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  @Input() optionList: DropdownOption[];
  @Input() isTemplateOnly: boolean = false;
  @Input() customApiCall: (searchConfig: UrlConfiguration, CUSTOM_FIELD_OBJECT?: any) => Observable<any>;
  @ViewChild('ddRef') ddRef: ElementRef;
  eventSubscriptionArr: any;

  formGroupObj: UntypedFormGroup;
  showLoader = false;
  subscription: any;
  selectedValue: any;
  formattedOptionList: IOption;

  constructor(private formService: FormsService, eventAction: EventAction) {
    super(eventAction);
  }

  ngOnInit() {
    this.formGroupObj = this.formService.createInputControl(this.fieldObj, this.dynamicAttributes);
    this.selectedValue = this.dynamicAttributes.value;
    this.subscription = this.fieldObj.action.subscribe(actionObj => {
      if (actionObj.actionType === ACTION_TYPE.RELOAD_COMPONENT_DATA) {
        this.loadData();
      } else if (actionObj.actionType === ACTION_TYPE.CLEAR_COMPONENT_DATA) {
        this.clearValue();
        if (actionObj.options && actionObj.options.clearList) {
          this.clearList();
        }
      }     
    });
    if (this.fieldObj.optionsConfig && this.fieldObj.optionsConfig.fetch === EventType.ONLOAD && this.dynamicAttributes.visibleValue) {
      this.loadData();
    } else if (this.fieldObj.optionList?.length ) {
      this.loadStaticData();
    }
  }
  loadStaticData() {
    this.loadFromOptionList();
  }

  ngAfterViewInit() {
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, this.ddRef?.nativeElement, this.fieldObj);
  }

  loadData() {    
    this.clearList();
    this.showLoader = true;        
    this.customApiCall(this.fieldObj.optionsConfig).subscribe( data => {
      this.fieldObj.optionList = data;  //TODO: this is an immutable object which shouldnt be modified
      this.loadFromOptionList();
    }, err => {
      this.fieldObj.optionList = [];
      this.showLoader = false;
      console.error(err);
    });
  }

  loadFromOptionList() {
    this.showLoader = false;
    this.formattedOptionList = this.optionsFormatter(this.fieldObj.optionList );
    let selectedOption = _.find(this.formattedOptionList, { value: _.isObject(this.selectedValue) ? this.selectedValue.value : this.selectedValue});
    this.formService.updateFieldValuebyName(this.fieldObj.baseProperties.formName, this.fieldObj.baseProperties.name, selectedOption);
    this.selectChangeHandler();
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  selectChangeHandler() {
    let dynamicAttributes:FieldDynamicAttributes,
      formValue = this.formGroupObj.value[this.fieldObj.baseProperties.name],
      dAttrVal = this.dynamicAttributes.value;

    if(!this.isTemplateOnly
      && !_.isEqual(dAttrVal, formValue)) { 
      dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
      dynamicAttributes.value = this.formGroupObj.value[this.fieldObj.baseProperties.name];
      this._dataChange.emit({ dynamicData: dynamicAttributes });
      if (this.formGroupObj.value[this.fieldObj.baseProperties.name] !== null) { //If null clear actions are already triggered
        this.fieldObj.onValueChange && this.fieldObj.onValueChange.actions 
          && this._dataChange.emit({ actions: this.fieldObj.onValueChange.actions });
      }
    }
  }

  applyLabelPlacementClass() {
    let className = 'input-label__placement-top';
    if (this.fieldObj.layoutProperties.labelPlacement
       && this.fieldObj.layoutProperties.labelPlacement.toLowerCase() === 'left') {
      className = 'input-label__placement-left';
    }

    return className;
  }

  clearValue() {
    this.formGroupObj.get(this.fieldObj.baseProperties.name).setValue(null);
    let dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
    dynamicAttributes.value = null;
    this._dataChange.emit({ dynamicData: dynamicAttributes });  
    this.fieldObj.onValueClear && this.fieldObj.onValueClear.actions 
      && this._dataChange.emit({ actions: this.fieldObj.onValueClear.actions });  
  }

  clearList() {
    this.fieldObj.optionList = [];
  }

  optionsFormatter(optionList) {
    if(optionList?.length) {

      return optionList.map(optionObj => {
        let modifiedOptionObj = {
          label: _.get(optionObj, this.fieldObj.labelKey),
          value: _.get(optionObj, this.fieldObj.valueKey)
        }
        return modifiedOptionObj;
      });
    }
    return [{label:"", value:""}];
  }

  compareFn(option1, option2) {
    return option1 && option2 ? option1.label === option2.label : option1 === option2;
  }

  ngOnDestroy() {
    this.formService.deleteInputControl(this.fieldObj);
    this.subscription.unsubscribe();
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }
}
