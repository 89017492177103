import { Field } from './field';
import { DropdownOption } from './dropdown-field';
import { UrlConfiguration } from '../../models/url-configuration';

export class TypeaheadField extends Field<DropdownOption> {
    type : string;
    searchConfig?: UrlConfiguration;
    searchMinLength?: number;
    displayKey: string;
    outputKey: string;
    placeholder: string;

    optionList: Array<DropdownOption>;
    

    constructor(options :{
        value?: string;
        type : string;
        optionList?: Array<DropdownOption>;
        searchConfig?: UrlConfiguration;
        searchMinLength?: number;
        displayKey: string;
        outputKey: string; 
        placeholder: string;    
        }) {
        super(options);
        this.type = options.type;
        this.optionList = options.optionList;
        this.searchConfig = options.searchConfig;
        this.searchMinLength = options.searchMinLength;
        this.displayKey = options.displayKey;
        this.outputKey = options.outputKey;
        this.placeholder = options.placeholder;
        this.setValue(options.value);  
    }
    
    getValue(): string {
        if (this.value === undefined) {
            return null;
        }
        return this.value.name;
    }

    setValue(value: string) {
        if (!value) {
            this.value = null;
            return;
        }
        if (this.optionList && this.optionList.length) {
            let that = this;
            this.optionList.some(function(option) {
                if (option.name === value) {
                    that.value = option;
                    return true;
                }
            });
            console.log("Value of typeahead field is: "+this.value);
            //Looks like value is not set properly.
        } else {
            console.warn("Typeahead field:" + this.baseProperties.name + " doesn't have static options.");
            this.value = null;
        }
    }
}