import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppDataService } from './app-data.service';
import { AuthenticationService } from './authentication.service';
import { InterceptorConfiguration } from '@lib-model/app-interfaces';
import * as _ from "lodash";
import { AppMessageService } from './app-message.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptor implements HttpInterceptor {

  constructor (private appData: AppDataService,
            private authenticationService: AuthenticationService,
            private messenger: AppMessageService,
            private router: Router,
            private route: ActivatedRoute,) {}

  private getJsonResponseError(response: HttpErrorResponse) {
    let error: any;
    if (response.error instanceof ArrayBuffer) {
      error = new TextDecoder("utf-8").decode(response.error);
      try {
        error = JSON.parse(error);
      } catch(e) {
        error = "";
      }
    } else {
      error = response.error;
    }
    return error;
  }
  
  private getInterceptorConfig(response: HttpErrorResponse): InterceptorConfiguration {
    let interceptorConfiguration = null;
    interceptorConfiguration = _.find(this.authenticationService.getInterceptorConfigurations(), (interceptorConfig: InterceptorConfiguration) => {
      if (response.status === +interceptorConfig.statusCode) {
        const messageCode = _.get(this.getJsonResponseError(response), interceptorConfig.messageCodeResponseAccessor);
        return messageCode && (+messageCode === +interceptorConfig.messageCode);
      }
      return false;
    });

    if (!interceptorConfiguration) {
      interceptorConfiguration = _.find(this.authenticationService.getInterceptorConfigurations(), (interceptorConfig: InterceptorConfiguration) => {
        return (response.status === +interceptorConfig.statusCode);
      });
    }
    return interceptorConfiguration;
  }

  private getInterceptorMessage(response: HttpErrorResponse, interceptorConfig: InterceptorConfiguration): string {
    let message = "";
    if (interceptorConfig && interceptorConfig.msgResponseAccessor) {
      message = _.get(this.getJsonResponseError(response), interceptorConfig.msgResponseAccessor)
    } else if (interceptorConfig.message) {
      message = interceptorConfig.message;
    }
    return message;
  }

  private handleInterceptorResponse(response: HttpErrorResponse, type: string): string {
    const interceptorConfig = this.getInterceptorConfig(response);
    let error = this.getJsonResponseError(response);
    let message = (error && error.message) || response.message;

    if (interceptorConfig) {
      message = this.getInterceptorMessage(response, interceptorConfig);
      this.messenger.showMessage("", message, interceptorConfig.type.toLowerCase() || type);
      this.appData.clearLocalStorageAndSharedData(interceptorConfig.clearSharedData || [])
      if (interceptorConfig.pageUrl) {
        this.router.navigate([interceptorConfig.pageUrl], { relativeTo: this.route });
      }
    }
    return message;
  }

  intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
      const headerObj = {
        'Content-Type' : 'application/json'
      };

      // if (this.authenticationService.isAuthenticated()) {
      //   headerObj['Authorization'] = `${this.appData.getAuthToken()}`;
      // }
      if (!request.headers.get("Content-Type") && !request.headers.get("File-Upload")) {
        request = request.clone({
          setHeaders: headerObj,
        });
      }

      return next.handle(request)
            .pipe(
                tap(evt => {
                  // if (evt instanceof HttpResponse) {
                  //   if (evt.headers) {
                  //     const key = evt.headers.get('authorization');
                  //     if(key) {
                  //       this.appData.setAuthToken(key);
                  //     }
                  //   }
                  // }
                  //this.handleInterceptorResponse()
                }),
                catchError((response: HttpErrorResponse) => {;
                  return throwError(this.handleInterceptorResponse(response, "error"));
                }));

    }
}
