<div class="dynamic-style-parent"
    *ngIf="dynamicAttributes.visibleValue" 
    [hidden]="!dynamicAttributes.visibleValue"
    ngClass="{{ applyLabelPlacementClass() }} {{ fieldObj.layoutProperties.css && fieldObj.layoutProperties.css.customClassName }}"
    [formGroup]="formGroupObj">
  <div #checkboxRef [formGroupName]="fieldObj.baseProperties.name">
    <label class="field-label" [ngClass]="{ 'label-required': dynamicAttributes.requiredValue && fieldObj.baseProperties.label }" for="{{ fieldObj.baseProperties.name }}">{{ fieldObj.baseProperties.label }}</label>
    <div *ngFor="let option of optionList; let count = index;"
        class="custom-control custom-checkbox dynamic-style-child"
        [ngClass]="{ 'custom-control-inline' : fieldObj.layoutProperties.orientation === 'horizontal' }">
        <input
        id="{{option.name}}"
        [value]="option.value"
        type="checkbox"
        class="custom-control-input"
        [formControlName]="option.value"
        (click)="onValueChange(option, $event)"/>
        <label for="{{option.name}}" class="custom-control-label">
            {{option.label}}
        </label>
    </div>
  </div>
</div>

