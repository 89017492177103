<div class="dynamic-style-parent"
    [ngClass]="fieldObj.layoutProperties.css && fieldObj.layoutProperties.css.customClassName">
    <div class="container-fluid dynamic-style-child">   
            <div class="row table-row table-header-cell">
                <div class="col-sm-12">
                    <div class="table-header-label">
                        <span>{{fieldObj.baseProperties.label}}</span>
                        <button class="btn btn-primary btn-sm table__refresh-btn" (click)="onRefresh()">Refresh</button>
                    </div>
                </div>
            </div> 
            <!-- To hide for smaller screen added d-none. For lg screen setting to flex as row class sets it to flex-->
            <div class="row d-none d-lg-flex header-bg-lg">
                <div [ngClass]="colConfig.colWidth ? ( colConfig.colWidth.unit === '' ? 'col-[colConfig.colWidth.value]' : 'col-lg-auto col-md-12') : 'col-lg col-md-12'" 
                    class="table-subheader-cell" *ngFor="let colConfig of fieldObj.colNameAccessorMap">
                    <div class="text-break" 
                        [ngStyle]="{ 'width': colConfig.colWidth && colConfig.colWidth.unit === 'px' ? colConfig.colWidth.value.toString()+colConfig.colWidth.unit : 'initial' }">
                        <span>{{colConfig.colName}}</span>
                    </div>
                </div>
            </div>
            <div class="row table-row" *ngIf="!dynamicAttributes.value || !dynamicAttributes.value.length"> 
                <div class="col-sm-12 table-body-cell"> No Bookings </div> 
            </div>
            <div class="row table-row table-body-bg" *ngFor="let rowData of dynamicAttributes.value">
                <div [ngClass]="colConfig.colWidth ? ( colConfig.colWidth.unit === '' ? 'col-lg-[colConfig.colWidth.value]' : 'col-lg-auto col-md-12') : 'col-lg col-md-12'" 
                    class="table-body-cell" *ngFor="let colConfig of fieldObj.colNameAccessorMap">
                    <!-- Width applied only for lg screen -->
                    <div class="d-none d-lg-block text-break" 
                        [ngStyle]="{ 'width': colConfig.colWidth && colConfig.colWidth.unit === 'px' ? colConfig.colWidth.value.toString()+colConfig.colWidth.unit : 'initial' }">
                        <span [ngClass]="{'table-row-delete': (colConfig.action&& colConfig.action.name && colConfig.action.name.toLowerCase() === 'delete')}"
                              (click)="onCellClick(colConfig.action, rowData)">
                            <span *ngIf="colConfig.value">{{ colConfig.value }}</span>
                            <!-- Use font-awesome class name for icons -->
                            <i *ngIf="colConfig.iconClass" [ngClass]="colConfig.iconClass"></i>
                            <span *ngIf="!(colConfig.value || colConfig.iconClass)">{{ getData(rowData, colConfig.accessor) }}</span>
                        </span>
                    </div>
                    <!-- For screen size md and below width not applied -->
                    <div class="d-lg-none text-break row">
                        <div class="col-5 col-name-xs">{{colConfig.colName}}</div>
                        <div class="col-7">
                            <span [ngClass]="{'table-row-delete': (colConfig.action && colConfig.action.name && colConfig.action.name.toLowerCase() === 'delete')}" 
                                  (click)="onCellClick(colConfig.action, rowData)">
                                <span *ngIf="colConfig.value">{{ colConfig.value }}</span>
                                <!-- Use font-awesome class name for icons -->
                                <i *ngIf="colConfig.iconClass" [ngClass]="colConfig.iconClass"></i>
                                <span *ngIf="!(colConfig.value || colConfig.iconClass)">{{ getData(rowData, colConfig.accessor) }}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>

