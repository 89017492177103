import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Field } from '@hmi/ui-component';
import { FieldValidationStatus } from '@lib-model/constants';
import { UrlParameterConfig } from '@lib-model/url-configuration';
import * as _ from "lodash";


@Injectable({
  providedIn: 'root'
})
export class FormsService {

  private formsArr: any[] = [];
  constructor() {
    this.formsArr = [];
  }

  public createForm(formName: string): UntypedFormGroup {
    if (!this.formsArr[formName]) {
      (<UntypedFormGroup>this.formsArr[formName]) = new UntypedFormGroup({});
    }
    return this.formsArr[formName];
  }

  public createInputControl(fieldObj: Field<any>, dynamicAttributes): UntypedFormGroup {
    this.createForm(fieldObj.baseProperties.formName);
    if (!this.formsArr[<string>fieldObj.baseProperties.formName].get(fieldObj.baseProperties.name)) {
      if (_.isArray(dynamicAttributes.value)) {
        this.formsArr[<string>fieldObj.baseProperties.formName].addControl(fieldObj.baseProperties.name, new UntypedFormGroup({}));
        this.createFormGroupData(fieldObj, dynamicAttributes);
      }
      this.formsArr[<string>fieldObj.baseProperties.formName].addControl(fieldObj.baseProperties.name, new UntypedFormControl(dynamicAttributes.value, this.populateValidators(dynamicAttributes, fieldObj)));
    } else {
      this.updateValidators(dynamicAttributes, fieldObj);
    }

    this.updateFieldProperty(dynamicAttributes, fieldObj);

    return this.formsArr[fieldObj.baseProperties.formName];
  }

  private createFormGroupData(fieldObj, dynamicAttributes) {
    const group = this.formsArr[<string>fieldObj.baseProperties.formName].get(fieldObj.baseProperties.name);
    fieldObj.optionList.map(option => {
      const selected = _.findIndex(dynamicAttributes.value, { value: option.value }) !== -1;
      group.addControl(option.value, new UntypedFormControl(selected, this.populateValidators(dynamicAttributes, fieldObj)));
    });
  }

  /**
   *
   * @param fieldObj
   * This function is used to delete a form control.
   * It returns false if control not found and true if found and removed.
   */
  public deleteInputControl(fieldObj: Field<any>): boolean {
    if (!this.formsArr[<string>fieldObj.baseProperties.formName].get(fieldObj.baseProperties.name)) {
      return false;
    } else {
      this.formsArr[<string>fieldObj.baseProperties.formName].removeControl(fieldObj.baseProperties.name);
      return true;
    }
  }

  public getFormList(): any[] {
      return this.formsArr;
  }

  public isFormValid(fieldObj: any): boolean {
    let isValid = true;
    if (fieldObj.baseProperties.formName) {
      let paramsArray: Array<UrlParameterConfig> = [];
      if (fieldObj.apiConfig) {
        if (fieldObj.apiConfig.payloadParams && fieldObj.apiConfig.payloadParams.length) {
          paramsArray = paramsArray.concat(fieldObj.apiConfig.payloadParams);
        }
        if (fieldObj.apiConfig.pathParams && fieldObj.apiConfig.pathParams.length) {
          paramsArray = paramsArray.concat(fieldObj.apiConfig.pathParams);
        }
        if (fieldObj.apiConfig.queryParams && fieldObj.apiConfig.queryParams.length) {
          paramsArray = paramsArray.concat(fieldObj.apiConfig.queryParams);
        }
      }
      if (fieldObj.validity === 'payloadFormatList' && paramsArray.length) {
        for (let index = 0; index < paramsArray.length; index++) {
          const controlObj = paramsArray[index] && this.formsArr[<string>fieldObj.baseProperties.formName].controls[paramsArray[index].mappedField];
          if (!controlObj.valid) {
            isValid = false;
            break;
          }
        }
      } else {
        isValid = this.formsArr[<string>fieldObj.baseProperties.formName].valid;
      }
    }
    return isValid;
  }

  public updateFieldProperty(dynamicAttributes, fieldObj: Field<any>) {
    const control = this.formsArr[<string>fieldObj.baseProperties.formName].get(fieldObj.baseProperties.name);
    if (control) {
      setTimeout(() => {
        dynamicAttributes.disabledValue ? control.disable() : control.enable();
      });
    }
  }

  public populateValidators(dynamicAttributes, fieldObj) {
    const validatorsArr: any = [];
    if (dynamicAttributes.requiredValue) {
      validatorsArr.push(Validators.required);
    }

    let properties = fieldObj;
    if (fieldObj.baseProperties.type === "dynamicField") {
      properties = fieldObj.customAttributes;
    }

    if (fieldObj.baseProperties.type.toLowerCase() === 'email') {
      validatorsArr.push(Validators.email);
    }

    if (!isNaN(properties.minLength)) {
      validatorsArr.push(Validators.minLength(+properties.minLength));
    }

    if (!isNaN(properties.maxLength)) {
      validatorsArr.push(Validators.maxLength(+properties.maxLength));
    }

    if (!isNaN(properties.minRange)) {
      validatorsArr.push(Validators.min(+properties.minRange));
    }

    if (!isNaN(properties.maxRange)) {
      validatorsArr.push(Validators.max(+properties.maxRange));
    }

    if (properties.minRequirementPattern) {
      validatorsArr.push(Validators.pattern(properties.minRequirementPattern));
    }
    return validatorsArr;
  }

  public updateValidators(dynamicAttributes, fieldObj) {
    const control = this.formsArr[<string>fieldObj.baseProperties.formName].get(fieldObj.baseProperties.name);
    if (control) {
      const validatorsArr: any = this.populateValidators(dynamicAttributes, fieldObj);
      control.setValidators(validatorsArr);
      control.updateValueAndValidity();
    }
  }

  public resetFormByName(formName: string) {
    this.formsArr[formName].reset();
  }

  public getFormValuesByName(formName: string): any {
    return this.formsArr[formName] && this.formsArr[formName].value || {};
  }

  public getAllFieldValidationStatus(formName: string): any {
    const fieldsStatusArr = {};
    if (this.formsArr[formName] && this.formsArr[formName].controls) {
      _.each(this.formsArr[formName].controls, (control, key) => {
        fieldsStatusArr[key] = {};
        fieldsStatusArr[key][FieldValidationStatus.$DIRTY] = control.dirty;
        fieldsStatusArr[key][FieldValidationStatus.$TOUCHED] = control.touched;
        fieldsStatusArr[key][FieldValidationStatus.$PRISTINE] = control.pristine;
      });
    }

    return fieldsStatusArr;
  }

  public getFieldValueById(formName: any, fieldId: any) {
      let formValuies = this.getFormValuesByName(formName); 
      if(!_.isEmpty(formValuies)) {
        return (_.isObject(formValuies[fieldId]) ? formValuies[fieldId].value : formValuies[fieldId]);
      }
      return null;
  }

  public setFieldValuebyId(formName: any, fieldId: any, value: any) {
    if (!_.isEmpty(this.formsArr[formName])) {
     this.formsArr[formName].controls[fieldId.replaceAll('$', "")].setValue(value);
    }
  }

  public updateFieldValuebyName(formName: any, fieldName: any, value: any) {
    if (!_.isEmpty(this.formsArr[formName])) {
     this.formsArr[formName].controls[fieldName].patchValue(value);
    }
  }

  public getFormFields(formName: any) {
    return this.formsArr[formName].controls;
  }
}
