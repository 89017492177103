import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Button } from '../../form/interfaces/button';
import { FormsService } from '../../services/forms.service';
import { ViewComponent } from '../view/view.component';
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { ActivatedRoute, Router } from '@angular/router';
import { EventAction } from '../../services/event.action';


@Component({
  selector: 'hmi-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fieldObj: Button;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  @Input() editMode?: boolean;
  @Input() formName?: String;
  @ViewChild('buttonRef') buttonRef: ElementRef;
  @ViewChild('linkRef') linkRef: ElementRef;
  eventSubscriptionArr: any;

  defaultClass: string;
  timerVal: any;
  spinnerTime: string;
  state = {
    loading: false,
    showTimer: false,
    disabled: false
  };
 
  public eventAction(event, fieldObj) {
    /**
     * Sequence - First actions will get executed, then redirection will happen
     * If we are showing timer, navigate is not applicable. So it is in end.
     */
    super.eventAction(event, fieldObj);
    if (this.fieldObj.href && this.fieldObj.href.length) {
      return;
    }
    event.preventDefault();
    if (Array.isArray(this.fieldObj.navigateToPage)) {      
      this._dataChange.emit({ customRedirect: this.fieldObj.navigateToPage });      
      return;      
    } else if (this.fieldObj.navigateToPage && this.fieldObj.navigateToPage.length) {
      this.router.navigate([this.fieldObj.navigateToPage], { relativeTo: this.route });
      return;
    } 
    

    if (this.fieldObj.onClick) {
      if (this.fieldObj.onClick.disabled) {
        this.state.disabled = true;
      }

      if (this.fieldObj.onClick.timer && !isNaN(this.fieldObj.onClick.timer.value)) {
        if (this.fieldObj.onClick.timer.visibility) {
          this.state.showTimer = true;
        }
        let timerInSeconds = (+this.fieldObj.onClick.timer.value) / 1000;
        this.spinnerTime = timerInSeconds + 's';
        setTimeout(() => {
            this.state.showTimer = false;
            if (this.fieldObj.onClick.timer.enableAfterExpire) {
              this.state.disabled = false;
            }
        }, timerInSeconds * 1000);
      }

      if (this.fieldObj.onClick.showLoaderOnClick) {
        this.state.loading = true;
      };
    }   
    
    /*if (this.fieldObj.baseProperties.type.toLowerCase() === 'submit' && this.formService.isFormValid(this.fieldObj)) {
      const dataToSend = {
        state: this.state,
        fieldObj: this.fieldObj,
        fieldsStatus: this.formService.getAllFieldValidationStatus(this.fieldObj.baseProperties.formName)
      }
      this._dataChange.emit({ submitFormData: dataToSend });
    } else if (this.fieldObj.baseProperties.type.toLowerCase() === 'reset') {
      this.formService.resetFormByName(this.fieldObj.baseProperties.formName);
    } else {

    }*/
  }

  constructor(private formService: FormsService,
    private route: ActivatedRoute,
    private router: Router,
    eventAction: EventAction) {
    super(eventAction);
  }

  ngOnInit() {
    this.defaultClass = 'btn ' + (this.fieldObj.layoutProperties.css && this.fieldObj.layoutProperties.css.themeClass
                      ? this.fieldObj.layoutProperties.css.themeClass
                      : 'btn-primary');      
  }

  ngAfterViewInit() {
    let eleRef: ElementRef;
    if (this.fieldObj.baseProperties.type === "link") {
      eleRef = this.linkRef;
    } else {
      eleRef = this.buttonRef;
    }
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, eleRef?.nativeElement, this.fieldObj);
  }

  isFormValid(): boolean {
    return !this.state.disabled && (this.fieldObj.baseProperties.type === 'submit' ? (this.formService.isFormValid(this.fieldObj) && !this.dynamicAttributes.disabledValue) : !this.dynamicAttributes.disabledValue);
  }

  ngOnDestroy() {
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }
}
