import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreService } from '../services/core.service';
import * as fromRoot from '@shared/store';
import * as pageActions from '@shared/store/page/page.action';
import { Store } from '@ngrx/store';
import { UtilsService } from '@shared/services/utils.service';
import * as _ from "lodash";
import { AppDataService } from '@shared/services/app-data.service';
import { CommonService } from '@shared/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { LayoutComponent } from '@renderer/layout/layout.component';
import { GenerateCSSService } from '@shared/services/generate-css.service';
import { UrlConfiguration } from '@lib-model/url-configuration';

@Component({
  selector: 'hmi-app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss']
})
export class BodyComponent extends LayoutComponent implements OnInit, OnDestroy {  

  constructor(public coreService: CoreService, public utilsService: UtilsService, public commonService: CommonService,
    private store: Store<fromRoot.State>, private route: ActivatedRoute,
    public appData: AppDataService, public generateCSSService: GenerateCSSService) {
      super(utilsService, coreService, appData, commonService, generateCSSService);
    this.sectionWiseFields$ = this.store.select(fromRoot.selectAllSectionFields);
    this.sectionsList$ = this.store.select(fromRoot.selectSectionConfig);
    this.fieldsDynamicAttr$ = this.store.select(fromRoot.selectFieldDynamicAttributeState);
    //this.pageData$ = this.store.select(fromRoot.selectPageData);
  }

  ngOnInit() {
    this.route.params.subscribe((params)=> {
      this.commonService.setRouteParams(params);
    });
      
    this.route.queryParams.subscribe((qparams)=> {
      this.commonService.setRouteQueryParams(qparams);
    });
    
    //this.store.dispatch(pageActions.LoadFields({ payload: this.jsonType.TYPE_FIELD }));
    this.store.dispatch(pageActions.LoadPageConfigAndData({ segment: this.jsonType.TYPE_BODY, sharedData: this.appData.getAllSharedData() }));
    this.sectionListSubscription = this.sectionsList$.subscribe( config => {
      this.sectionsList = _.cloneDeep(config);
      this.loadSectionCSS(this.sectionsList, this.jsonType.TYPE_BODY);
    });
    this.fieldDynamicAttrSubscription = this.fieldsDynamicAttr$.subscribe( dynamicAttrData => {
      this.dynamicFieldsLoader(dynamicAttrData);      
    });
    this.pageConfigsAndDataSubscription = this.sectionWiseFields$.subscribe((data) => {
        this.loadFieldCSS(data, this.jsonType.TYPE_BODY);
        this.pageDataConfigure(data);        
      }, (err) => {
        this.pageDoesNotExist = true;
      }, () => console.log('Completed')
    );      

    
  }  

  trackByFn(index) {
    return 'body' + index;
  }

  fieldValueChange(newFieldObj) {
    this.coreService.handleFieldUserInput(newFieldObj, this.fields, this.fieldsDynamicAttr);
  }  

  public customApiCall = (searchConfig: UrlConfiguration, CUSTOM_FIELD_OBJECT?: any) => {
    return this.coreService.callApi(searchConfig, this.fields, this.fieldsDynamicAttr, CUSTOM_FIELD_OBJECT);
  }

  ngOnDestroy() {
    this.pageConfigsAndDataSubscription.unsubscribe();
    this.sectionListSubscription.unsubscribe();
    this.fieldDynamicAttrSubscription.unsubscribe();
    this.store.dispatch(pageActions.ClearPageConfigAndData({ payload: '' }));
  }

}
