import { Field } from './field';
import { DropdownOption } from './dropdown-field';
import * as _ from 'lodash';

export class CheckboxField extends Field<Array<DropdownOption>> {
    optionList: Array<DropdownOption>;
    displayKey: string;
    outputKey: string;

    constructor(options :{
      value?: any;
      type : string;
      optionList: Array<DropdownOption>;
      displayKey: string;
      outputKey: string;
      orientation: string;
      }) {
      super(options);
      this.optionList = options.optionList;
      this.displayKey = options.displayKey;
      this.outputKey = options.outputKey;
      this.initValues(options.value);
    }

    initValues(valArr: Array<any>) {
        if (valArr && valArr.length) {
            for (let index = 0; index < valArr.length; index++) {
                this.setValue(valArr[index]);
            }
        } else {
            this._value = [];
        }
    }

    getValue(): Array<DropdownOption> {
        if (!(this.value && this.value.length)) {
            return [];
        }
        console.log("Checkbox value: " + (this.value && this.value.name));
        return this._value;
    }

    setValue(value: string) {
        if (this.optionList && this.optionList.length) {
            const that = this;
            this.optionList.some(function(option) {
                if (option.value === value) {
                    that._value.push(option);
                    return true;
                }
            });
        } else {
            console.error("Dropdown field:" + this.baseProperties.name + " value not found in available options.");
            this._value = [];
        }
    }

    get value() {
        return this._value;
    }

    set value(valArr: any) {
        valArr = _.uniq(valArr);
        this.initValues(valArr);
    }
}