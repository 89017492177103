import { NgModule } from '@angular/core';
import { CharTextComponent } from './char-text/char-text.component';
import { ButtonComponent } from './button/button.component';
import { ModalComponent } from './modal/modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { StaticTextComponent } from './static-text/static-text.component';
import { StaticIconComponent } from './static-icon/static-icon.component';
import { NavItemGroup } from './nav-item-group/nav-item-group.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ViewComponent } from './view/view.component';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { RadioComponent } from './radio/radio.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { TableComponent } from './table/table.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { RouterModule } from '@angular/router';
import { DynamicStyleDirective } from '../directives/dynamic-style.directive';
import { ExtensionsModule } from '@hmi/extensions';
import { StaticImageComponent } from './static-image/static-image.component';
import { DynamicCreatorComponent } from './dynamic-creator/dynamic-creator.component';
import { CommonDirectivesModule } from '../common-directives/common-directives.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PrimengSelectorsModule } from 'external-components';


@NgModule({
  declarations: [CharTextComponent, ButtonComponent,DropdownComponent, StaticTextComponent,
    ViewComponent, CheckboxComponent, DropdownMenuComponent, TypeaheadComponent, RadioComponent,
    NavMenuComponent, TableComponent, DateTimeComponent, NavItemGroup, DynamicStyleDirective,
    StaticIconComponent, StaticImageComponent, ModalComponent, DynamicCreatorComponent, FileUploadComponent],
  imports: [
    BrowserModule, FormsModule, ReactiveFormsModule, NgbModule, RouterModule, ExtensionsModule, CommonDirectivesModule,
    MatFormFieldModule, MatIconModule, MatInputModule, MatDividerModule, MatListModule,
    MatProgressBarModule,PrimengSelectorsModule 

  ],
  exports: [CharTextComponent, ButtonComponent, DropdownComponent, StaticTextComponent,
    CheckboxComponent, DropdownMenuComponent, TypeaheadComponent, RadioComponent,
    NavMenuComponent, TableComponent, DateTimeComponent, NavItemGroup, StaticIconComponent, StaticImageComponent,
    ModalComponent, DynamicCreatorComponent, FileUploadComponent]
})
export class ElementsModule { }
