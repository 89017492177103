import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StyleTagId } from '@lib-model/constants';
import { CssConfiguration } from 'libs/ui-component/src/lib/form/interfaces/css-interface';
import { cssKeyList } from 'libs/ui-component/src/lib/models/css-key-contants';
import * as _ from "lodash";

interface CssValues {
    value: string;
    hasValue: boolean;
}

export enum CssType {
    TYPE_FIELD = "field",
    TYPE_BODY = "body",
    TYPE_SECTION = "section"
}

@Injectable({
  providedIn: 'root'
})
export class GenerateCSSService {
    private xsMediaQuery = "\n@media (min-width: 100px)";
    private smMediaQuery = "\n@media (min-width: 768px)";
    private mdMediaQuery = "\n@media (min-width: 992px)";
    private lgMediaQuery = "\n@media (min-width: 1200px)";

    private xsCSS: CssValues;
    private smCSS: CssValues;
    private mdCSS: CssValues;
    private lgCSS: CssValues;

    private bodySpecificity = {
        className: ".body-specificity"
    };

    private loadedPageRouteName: Array<string>;

  constructor(@Inject(DOCUMENT) private documentService: Document, private router: Router) { 
      this.initializeCSS();
      this.loadedPageRouteName = [];
  }

  private initializeCSS() {
    this.xsCSS = {
        value: this.xsMediaQuery + " {\n",
        hasValue: false
    };
    this.smCSS = {
        value: this.smMediaQuery + " {\n",
        hasValue: false
    };
    this.mdCSS = {
        value: this.mdMediaQuery + " {\n",
        hasValue: false
    };
    this.lgCSS = {
        value: this.lgMediaQuery + " {\n",
        hasValue: false
    };
  }

  private readCSSFromObject(type: CssType, cssProperties: any, selectorName: string): string {
    if (type === CssType.TYPE_BODY) {
        selectorName += this.bodySpecificity.className;
    }

    const cssStr: CssValues = {
        value: selectorName + " { ",
        hasValue: false
    };
    let finalCss = "";
    _.forEach(cssProperties, (value, key) => {
        cssStr.value += (key + ": " + value + ";"); 
        cssStr.hasValue = true;
    });

    if (cssStr.hasValue) {
        finalCss += cssStr.value + " } ";
    }

    return finalCss;
}


buildCSSConfig(cssConfig: CssConfiguration, type: CssType, selectorName?: string) {
    let cssStr = "";
    if (!_.isEmpty(cssConfig) && (selectorName || cssConfig.selectorName)) {
        let finalSelector = "";
        if ((type === CssType.TYPE_FIELD || type === CssType.TYPE_SECTION) && selectorName) {
            finalSelector += "#" + selectorName + " ";
        } else {
            finalSelector += selectorName;
        }
        if (cssConfig.selectorName &&  type !== CssType.TYPE_BODY) {
            finalSelector += cssConfig.selectorName;
        }
        if (cssConfig.xs && !_.isEmpty(cssConfig.xs)) {
            this.xsCSS.value += this.readCSSFromObject(type, cssConfig.xs, finalSelector);
            this.xsCSS.hasValue = true;
        }

        if (cssConfig.sm && !_.isEmpty(cssConfig.sm)) {
            this.smCSS.value += this.readCSSFromObject(type, cssConfig.sm, finalSelector);
            this.smCSS.hasValue = true;
        }

        if (cssConfig.md && !_.isEmpty(cssConfig.md)) {
            this.mdCSS.value += this.readCSSFromObject(type, cssConfig.md, finalSelector);
            this.mdCSS.hasValue = true;
        }

        if (cssConfig.lg && !_.isEmpty(cssConfig.lg)) {
            this.lgCSS.value += this.readCSSFromObject(type, cssConfig.lg, finalSelector);
            this.lgCSS.hasValue = true;
        }
    }
}

  readCSSConfig(cssConfig: CssConfiguration | Array<CssConfiguration>, type: CssType, selectorName: string) {
    if (Array.isArray(cssConfig)) {
        _.forEach(cssConfig, (config: CssConfiguration) => {
            this.buildCSSConfig(config, type, selectorName);
        });
    } else {
        this.buildCSSConfig(cssConfig, type, selectorName);
    }
  }

  applyCSSConfig(id?: string) {
    id = (id === StyleTagId.body) ? id : StyleTagId.page;
    const styleTag = <Element>this.documentService.head.querySelector('#' + id);
    if (this.xsCSS.hasValue) {
        styleTag.innerHTML += (this.xsCSS.value + "\n}");
    }
    if (this.smCSS.hasValue) {
        styleTag.innerHTML += (this.smCSS.value + "\n}");
    }
    if (this.mdCSS.hasValue) {
        styleTag.innerHTML += (this.mdCSS.value + "\n}");
    }
    if (this.lgCSS.hasValue) {
        styleTag.innerHTML += (this.lgCSS.value + "\n}");
    }

    this.initializeCSS();
  }
}
