import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, ElementRef, ViewChild, AfterViewInit  } from '@angular/core';

import { UntypedFormGroup } from '@angular/forms';
import { FormsService } from '../../services/forms.service';
import { CharField } from '../../form/interfaces/char-field';
import { ViewComponent } from '../view/view.component';
import * as _ from "lodash";
import { FieldDynamicAttributes } from '../../form/interfaces/field-dynamic-attributes';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ACTION_TYPE } from '@lib-model/events';
import { EventAction } from '../../services/event.action';

@Component({
  selector: 'hmi-char-text',
  templateUrl: './char-text.component.html',
  styleUrls: ['./char-text.component.scss']
})


export class CharTextComponent extends ViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() fieldObj: CharField;
  @Input() dynamicAttributes: FieldDynamicAttributes;
  @Input() editMode: boolean;
  @Input() isTemplateOnly = false;
  @ViewChild('textBoxRef') textBoxRef: ElementRef;
  @ViewChild('textAreaRef') textAreaRef: ElementRef;
  eventSubscriptionArr: any;
  formGroupObj: UntypedFormGroup;
  data: any;
  debounceValueChange: Subject<void|string> = new Subject();
  subscription: any;
  //private eventAction: EventAction;

  constructor(private formService: FormsService, eventAction: EventAction) {
    super(eventAction);
  }
  ngOnInit() {
    this.formGroupObj = this.formService.createInputControl(this.fieldObj, this.dynamicAttributes);
    this.debounceValueChange.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.onValueChange();
    });
    this.subscription = this.fieldObj.action.subscribe(actionObj => {
      if (actionObj.actionType === ACTION_TYPE.CLEAR_COMPONENT_DATA) {
        this.clearValue();
      }
    });
  }

  ngAfterViewInit() {
    let eleRef: ElementRef;
    if (this.fieldObj.baseProperties.type === "textarea") {
      eleRef = this.textAreaRef;
    } else {
      eleRef = this.textBoxRef;
    }
    this.eventSubscriptionArr = this.attachEventsToElement(this.fieldObj.events, eleRef?.nativeElement, this.fieldObj);
  }

  applyLabelPlacementClass() {
    let className = 'input-label__placement-top';
    if (this.fieldObj.layoutProperties.labelPlacement
       && this.fieldObj.layoutProperties.labelPlacement.toLowerCase() === 'left') {
      className = 'input-label__placement-left';
    }

    return className;
  }

  // This is a basic setter that the forms API is going to use
  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnChange() {


  }

  registerOnTouched() {

  }

  onValueChange() {
    console.log('I am in onValueChange()');
    let dynamicAttributes;
    if(!this.isTemplateOnly
      && this.fieldObj.value !== this.formGroupObj.value[this.fieldObj.baseProperties.name]) { //Value change should be triggered only if actual value has changed.
        dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
        dynamicAttributes.value = this.formGroupObj.value[this.fieldObj.baseProperties.name];
      this._dataChange.emit({ dynamicData: dynamicAttributes });
      if (this.formGroupObj.value[this.fieldObj.baseProperties.name] !== null) {
        this.fieldObj.onValueChange && this.fieldObj.onValueChange.actions
          && this._dataChange.emit({ actions: this.fieldObj.onValueChange.actions });
      }
    }
  }

  clearValue() {
    this.formGroupObj.get(this.fieldObj.baseProperties.name).setValue(null);
    const dynamicAttributes = _.cloneDeep(this.dynamicAttributes);
    dynamicAttributes.value = null;
    this._dataChange.emit({ dynamicData: dynamicAttributes });
    this.fieldObj.onValueClear && this.fieldObj.onValueClear.actions
      && this._dataChange.emit({ actions: this.fieldObj.onValueClear.actions });
  }

  ngOnDestroy() {
    this.formService.deleteInputControl(this.fieldObj);
    this.subscription.unsubscribe();
    this.eventSubscriptionArr?.forEach((subObj) => {
      subObj.unsubscribe();
    });
    this.eventSubscriptionArr = null;
  }

}
